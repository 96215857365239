import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

export default function Top() {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    return (
        <div className="fixed bottom-10 right-10 z-10">
            <AnimatePresence>
                {isVisible && (
                    <motion.div
                        key="top-button"
                        initial={{ opacity: 0, y: 100 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 100 }}
                        whileTap={{ scale: 0.9 }}
                        whileHover={{ scale: 1.1 }}
                        onClick={scrollToTop}
                        className="cursor-pointer rounded-full w-[60px] h-[60px] flex items-center justify-center bg-slate-300 bg-opacity-20 backdrop-blur-sm"
                    >
                        <FontAwesomeIcon size="2xl" icon={faArrowUp} />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

import { AnimatePresence } from 'framer-motion';
import { Squeeze as Hamburger } from 'hamburger-react';
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

function NavigationBar() {
  const [isOpen, setOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) &&
        buttonRef.current && !buttonRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    const handleScroll = () => {
      setOpen(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [menuRef, buttonRef]);

  return (
    <nav className='flex justify-between items-center z-10'>
      <div className='m-10 text-4xl'>
        <a href='/'>
          Td.
        </a>
      </div>
      <div className="hidden md:block text-2xl items-center">
        <a className="me-10" href="/">Accueil</a>
        <a className="me-10" href="#projets">Projets</a>
        <a href="#contact">Contact</a>
      </div>
      <div className='m-10 md:hidden z-30' ref={buttonRef}>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={menuRef}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col md:hidden gap-y-4 text-3xl bg-slate-300 bg-opacity-50 backdrop-blur-sm z-20 fixed left-0 right-0 top-[7.5rem] p-10 shadow-lg"
          >
            <div onClick={() => setOpen(false)}>
              <a href="/">Accueil</a>
            </div>
            <div onClick={() => setOpen(false)}>
              <a href="#projets">Projets</a>
            </div>
            <div onClick={() => setOpen(false)}>
              <a href="#contact">Contact</a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}

export default NavigationBar;

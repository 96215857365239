import React, { useState } from 'react';
import { motion } from 'framer-motion';

export default function HomePage() {
  const [imageSrc, setImageSrc] = useState('/images/computer.webp');

  const handleTap = () => {
    setImageSrc(prevSrc =>
      prevSrc === '/images/computer.webp'
        ? '/images/happy.webp'
        : '/images/computer.webp'
    );
  };

  return (
    <main>
      <div className='flex items-center justify-around lg:w-1/2 mx-auto h-[400px] md:h-[800px] mb-20'>
        <div>
          <div className='flex'>
            <div className='text-3xl md:text-6xl text-nowrap'>Thomas d.</div>
          </div>
          <h2>Développeur</h2>
        </div>
        <motion.img
          whileTap={{ scale: 1.1 }}
          whileHover={{ rotate: 2 }}
          src={imageSrc}
          width={250}
          height={250}
          alt="Emoji d'un homme derrière un l'écran d'un Macbook"
          onTap={handleTap}
        />
      </div>
      <motion.div
        whileHover={{ rotate: 2 }}
        whileTap={{ rotate: -2 }}
        className='relative w-[300px] md:w-1/2 mx-auto'>
        <div className='shadow-md backdrop-blur-lg bg-opacity-10 bg-slate-400 p-8 rounded-3xl'>
          <h1>Je m'appelle Thomas Deschenes </h1>, je suis un développeur fullstack passionné par la programmation et les nouvelles technologies.
          J'ai commencé à coder en 2020. <br /><br />J'ai commencé par apprendre le HTML, le CSS et le C#, puis
          j'ai appris le Javascript, le React, le Node.js, PHP, le Laravel, le Python et bien d'autres technologies. Je connais
          également la méthologie Agile, Scrum, Kanban et SAFe<br /><br />J'ai réalisé
          plusieurs projets personnels et professionnels, que vous pouvez retrouver sur mon portfolio. N'hésitez pas à me contacter
          pour toute question ou proposition.
          <div className='text-gray-400 text-sm'>
            Reçu le {
              new Date().toLocaleDateString('fr-FR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })
            } à {
              new Date().toLocaleTimeString('fr-FR', {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
              })
            }
          </div>
        </div>
      </motion.div>
    </main>
  );
}
